<template>
  <div class="cs-button-group flex flex-row items-center justify-center">
    <router-link :class="['flex', 'justify-center', 'items-center', {active: type === 'notice'}]" :to="{name: 'NoticeList'}">공지사항</router-link>
    <router-link :class="['flex', 'justify-center', 'items-center', {active: type === 'qna'}]" :to="{name: 'QnaList'}">1:1 문의</router-link>
    <router-link :class="['flex', 'justify-center', 'items-center', {active: type === 'faq'}]" :to="{name: 'FaqList'}">자주묻는질문</router-link>
  </div>
</template>

<script>
export default {
  name: 'CSTab',
  props: {
    type: String
  }
}
</script>

<style scoped lang="scss">
.cs-button-group {
  margin-bottom: 90px;

  a {
    width: 319px;
    height: 62px;
    border: solid 1px #ddd;

    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: -0.9px;
    text-align: left;
    color: #adadad;

    &.active {
      background-color: #283aef;
      color: #fff;
      border: 0;
    }
  }
}

@media (max-width: 639px) {
  .cs-button-group {
    margin-bottom: 50px;
    a {
      width: 105px;
      height: 40px;
      font-size: 12px;
    }
  }
}
</style>