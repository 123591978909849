<template>
<CSLayout :type="'faq'">
  <PaginationLayout :lastPage="lastPage" :onPage="onPage">
    <div
      @click="() => onActive(item.id)"
      class="faq-item"
      v-for="item in data"
      :key="item.id">
      <div class="title flex flex-row items-center">
        <div class="icon flex items-center justify-center">Q</div>
        <p>{{ item.title }}</p>
      </div>
      <div :class="['content', 'flex', 'flex-row', {hidden: activeId !== item.id}]">
        <div class="icon flex items-center justify-center">A</div>
        <p>{{ item.content }}</p>
      </div>
    </div>

    <div class="empty" v-if="data.length === 0">
      등록된 글이 없습니다.
    </div>
  </PaginationLayout>
</CSLayout>
</template>

<script>
import CSLayout from '@/components/CSLayout.vue';
import PaginationLayout from '@/components/PaginationLayout.vue';
import services from '@/services';

export default {
  name: 'FaqList',
  components: {
    CSLayout,
    PaginationLayout,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      lastPage: 1,
      currentPage: 1,
      data: [],
      activeId: null,
    }
  },
  methods: {
    async getData() {
      const {data} = await services.faqList({paginate: 10, page: this.currentPage});
      this.data = data;
    },
    onPage(value) {
      this.activeId = null;
      this.currentPage = value;
      this.getData();
    },
    onActive(id) {
      this.activeId = id;
    }
  },
}
</script>

<style scoped lang="scss">
.faq-item {
  border-bottom: 1px solid #dedede;
  cursor: pointer;

  .title {
    padding-left: 10px;
    padding-right: 10px;
    height: 55px;

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: left;
      color: #353535;
    }
  }

  .content {
    padding-top: 30px;
    padding-bottom: 80px;
    padding-left: 65px;
    padding-right: 65px;
    background-color: #f1f1f1;
    border-top: 1px solid #8e8e8e;

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.8px;
      text-align: left;
      color: #7b7b7b;
      white-space: pre-line;
    }

    .icon {
      background-color: #283aef;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 16px;
    padding-bottom: 2px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #fff;
    margin-right: 10px;
    background-color: #353535;
  }
}

.empty {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: -0.7px;
  color: #353535;
  padding: 30px;
  text-align: center;
}

@media (max-width: 639px) {
  .faq-item {
    .icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      font-size: 10px;
    }
    .title {
      height: 35px;
      p {
        font-size: 12px;
      }
    }
    .content {
      padding: 20px 20px 20px 30px;
      p {
        font-size: 11px;
      }
    }
  }
}
</style>