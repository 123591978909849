<template>
  <PageHeaderLayout :title="title">
    <a v-on:click="goToList" class="flex flex-row items-center" v-if="hasMenu">
      <img src="@/assets/menu.png" />
      목록으로
    </a>
  </PageHeaderLayout>
</template>

<script>
import PageHeaderLayout from '@/components/PageHeaderLayout.vue';

export default {
  name: 'PageHeader',
  components: { PageHeaderLayout },
  props: {
    title: String,
    hasMenu: {
      type: Boolean,
      default: true,
    },
    link: String
  },
  methods: {
    goToList() {
      this.$router.push({ name: this.link })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.14px;
  text-align: left;
  color: #a6a6a6;

  img {
    width: 14px;
    margin-right: 6px;
  }
}
</style>