<template>
  <Layout :title="'고객센터'">
    <div class="container flex flex-col cs-list-wrapper">
      <CSTab :type="type" />

      <slot>
      </slot>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue';
import CSTab from '@/components/CSTab.vue';

export default {
  name: 'CSLayout',
  components: {
    Layout,
    CSTab,
  },
  props: {
    type: String
  },
}
</script>

<style lang="scss" scoped>
.cs-list-wrapper {
  margin-bottom: 80px;
  padding-top: 63px;
  min-height: 350px;
  overflow: hidden;
}
</style>